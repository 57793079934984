import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PhoneInput, {formatPhoneNumber} from "react-phone-number-input";
import logo from "./images/maldiniLogo.png";
import firebase from "./firebase";
import fondo from "./images/fondo.jpg";

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

function addClassToId(id, className) {
    var element, name, arr;
    element = document.getElementById(id);
    name = className;
    arr = element.className.split(" ");
    if (arr.indexOf(name) == -1) {
        element.className += " " + name;
    }
}
function removeClassToId(id, classname) {
    var element = document.getElementById(id);
    element.className = element.className.replace(classname, "");

}

export default class Form extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            name:'',
            lastName: '',
            email: '',
            country: 'Argentina',
            birth: '',
            tel: '',
            uid: '',
            ID: '',
            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
            headerText: '',
            dateType: 'text',
        };
    }

    dateTypeToDate = () => {
      this.setState({dateType: 'date'});
    };
    dateTypeToText = () => {
      this.setState({dateType: 'text'});
    };

    onSubmit = () => {
        removeClassToId('load-user', 'sending');
        addClassToId('load-user', 'is-success');
        removeClassToId("js-personal-info","slide-in-right");
        addClassToId("js-personal-info", "slide-out-left");
        addClassToId("js-submit","slide-in-right2");
       /* if (this.state.name && this.state.lastName && this.state.email && this.state.birth && this.state.tel && this.state.country){
            if (validateEmail(this.state.email)){
                addClassToId('load-user', 'sending');
                var db = firebase.firestore();
                const context = this;
                db.collection('users').where('campaign', '==', 'thekitmaker').where('email','==', this.state.email).get()
                    .then(function (snapshot) {
                        if (snapshot.size > 0){
                            removeClassToId('load-user', 'sending');
                            addClassToId('load-user', 'is-success');
                            removeClassToId("js-personal-info","slide-in-right");
                            addClassToId("js-personal-info", "slide-out-left");
                            addClassToId("js-submit","slide-in-right");
                            snapshot.forEach(function(doc) {
                                // doc.data() is never undefined for query doc snapshots
                                context.setState({ID: doc.id})
                            });

                        }else {
                            db.collection('users').add({
                                name: context.state.name,
                                lastName: context.state.lastName,
                                email: context.state.email,
                                birth: context.state.birth,
                                tel: context.state.tel,
                                country: context.state.country,
                                friendUID: context.state.friendUID,
                                campaign: 'thekitmaker'
                            }).then(function (doc) {
                                context.setState({ID: doc.id});
                                removeClassToId('load-user', 'sending');
                                addClassToId('load-user', 'is-success');
                                removeClassToId("js-personal-info","slide-in-right");
                                addClassToId("js-personal-info", "slide-out-left");
                                addClassToId("js-submit","slide-in-right");
                            })
                        }
                    });
                /!*var docRef = db.collection("users").doc(this.state.uid);
                docRef.get().then(function(doc) {
                    if (doc.exists) {
                        removeClassToId('load-user', 'sending');
                        addClassToId('load-user', 'is-success');
                        removeClassToId("js-personal-info","slide-in-right");
                        addClassToId("js-personal-info", "slide-out-left");
                        addClassToId("js-submit","slide-in-right");
                    } else {
                        docRef.set({
                            name: context.state.name,
                            lastName: context.state.lastName,
                            email: context.state.email,
                            birth: context.state.birth,
                            tel: context.state.tel,
                            country: context.state.country,
                            friendUID: context.state.friendUID
                        }).then(function (doc) {
                            removeClassToId('load-user', 'sending');
                            addClassToId('load-user', 'is-success');
                            removeClassToId("js-personal-info","slide-in-right");
                            addClassToId("js-personal-info", "slide-out-left");
                            addClassToId("js-submit","slide-in-right");
                        })
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                *!/
            }
            else {
                addClassToId('formEmail', 'has-error');
            }
        }
        else {
            addClassToId('formName', 'has-error');
            addClassToId('formLastName', 'has-error');
            addClassToId('formEmail', 'has-error');
            addClassToId('formBirth', 'has-error');
            addClassToId('formTel', 'has-error');
            addClassToId('formCountry', 'has-error');
        }*/
    };
    handleChangeTel = (value) => {

        if (value){
            if (value.length >= 0) {
                this.setState({ tel: formatPhoneNumber(value, 'International') })
            } else {
                this.setState({
                    tel: value,
                })
            }
        }

    };

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
        removeClassToId('formName', 'has-error');
        removeClassToId('formLastName', 'has-error');
        removeClassToId('formEmail', 'has-error');
        removeClassToId('formBirth', 'has-error');
        /*removeClassToId('formTel', 'has-error');*/
        removeClassToId('formCountry', 'has-error');
    };
    componentDidMount() {

    }

    render() {
        return (
            <div style={{backgroundImage: 'url(' + fondo + ')', backgroundSize: '100% auto'}} className={'rediFormWrapper fadeInOnLoad'}>
                <nav className="navbar navbar-default">
                    <div className="container">

                        <div className="navbar-header">
                            <a className="navbar-brand" href="#header"><img src={logo} alt=""/></a>
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                    data-target="#main-navbar" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>


                    </div>

                </nav>
                <div className="personal-info redirectForm slide-in-right" id="js-personal-info">
                    <div className="form-group text-center">
                        {/*<a href="javascript:void(0);" className="btn" onClick={this.onBackClick}
                                                                           id="prev-product-info">Go Back</a>*/}
                        <h4 className="name">Recibirás tu descuento en el email que indiques.</h4>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-6" id={'formName'}>
                            <label className={'formLabel'}>Nombre</label>
                            <input type="text" name="first_name" className="form-control"
                                   required value={this.props.name}
                                   onChange={this.props.handleChange('name')}/>
                        </div>
                        <div className="form-group col-sm-6" id={'formLastName'}>
                            <label className={'formLabel'}>Apellido</label>
                            <input type="text" name="last_name" className="form-control"
                                    required value={this.props.lastName}
                                   onChange={this.props.handleChange('lastName')}/>
                        </div>
                    </div>
                    <div className="form-group" id={'formEmail'}>
                        <label className={'formLabel'}>E-mail</label>
                        <input type="email" name="email" className="form-control"
                               required value={this.props.email}
                               onChange={this.props.handleChange('email')}/>
                    </div>


                    <div className="row">
                        <div className="form-group col-sm-6" id={'formBirth'}>
                            <label className={'formLabel'}>Fecha de nacimmiento</label>
                            <input type={'date'} name="birth" className="form-control"

                                   /*onFocus={this.dateTypeToDate} onBlur={this.dateTypeToText}*/

                                   required value={this.props.birth} onChange={this.props.handleChange('birth')}/>
                        </div>
                        <div className="form-group col-sm-6" id={'formCountry'}>
                            <label className={'formLabel'}>DNI</label>
                            <input type="number" name="DNI" className="form-control"
                                   placeholder="DNI"
                                   required value={this.props.dni} onChange={this.props.handleChange('dni')}/>
                        </div>
                       {/* <div className="form-group col-sm-6" id={'formTel'}>
                            <label className={'formLabel'}>Telefono</label>
                            <PhoneInput
                                id={'inputTel'}
                                className={'form-control'}
                                value={this.props.tel}
                                onChange={tel => this.props.handleChangeTel(tel)}/>
                             <input id={'inputTel'} type="number" name="tel" className="form-control" placeholder="Numero de teléfono" pattern="^[0-9-+s()]*$"
                                               required value={this.state.tel} onChange={this.handleChange('tel')}/>
                        </div>*/}
                    </div>
                    {/*<div className="form-group" id={'formCountry'}>
                        <label className={'formLabel'}>DNI</label>
                        <input type="number" name="DNI" className="form-control"
                               placeholder="DNI"
                               required value={this.props.dni} onChange={this.props.handleChange('dni')}/>
                    </div>*/}

                    <div className="submit-area-2 row m0">
                        <button
                            onClick={this.props.onSubmit}
                            id='load-user'
                            className="btn btn-rounded js-preorder-btn btn-block">
                            <span>CONTINUAR</span>
                        </button>

                    </div>
                </div>
                <div className="personal-info redirectForm" id="js-submit">
                    <h2 className="edition checkMail">Ya enviamos el código de descuento a tu e-mail.
                        <br/>
                        ¡Ahora invitale una Corona a tus amigos y aumentá tus chances de ganar el Bucket!
                    </h2>

                    <div className="submit-area-2 row m0">
                        <a href={"https://wa.me/?text="  + '¡' + this.props.name + ' te invitó una corona para festejar el aniversario de Maldini! ' +
                        " https%3A%2F%2Fmaldini.getviral.me%2F" + this.props.ID}
                           data-action="share/whatsapp/share">
                            <button
                                type="submit"
                                id='load-user'
                                className="btn btn-rounded js-preorder-btn btn-block is-success">
                                        <span>
                                            <ion-icon name="logo-whatsapp" class="logoWhatsapp"></ion-icon>
                                            Enviar por Whatsapp
                                        </span>

                            </button>
                        </a>
                    </div>

                </div>
            </div>
        )
    }
}